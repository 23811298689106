:root {
  --sw: 1;

  --site-padding: 2rem;
  --site-row-height: 3.8rem;
  --pre-footer-margin: 4rem;
  --search-padding: 3.9rem;

  @media only screen and (max-width: 700px) {
    --site-padding: 1.6rem;
    --search-padding: 2rem;
  }

  // HEADER
  --header-height: 7.4rem;

  @media only screen and (max-width: 1109px) {
    --header-height: 5.6rem;
  }

  // COLORS
  --color-border: #808080;
  --color-light-border: #d8d8d8;
  --color-error-rgb: 226, 66, 77;

  --color-error: rgb(var(--color-error-rgb));
  --color-black: #222;
  --color-creamwhite: #fbfbf8;
  --color-gray: rgba(0, 0, 0, 0.09);
  --color-grey: var(--color-gray);
  --color-blue: #1b6dd6;
  --color-medium-grey: #666;
  --color-medium-gray: var(--color-medium-grey);
  --color-yellow: #f0f3c0;
  --color-orange: #ff6600;
  // if this should exist (cant find references to it) then it should be lighter than --color-black
  // --color-dark-grey: #202020;
  // --color-dark-gray: var(--color-dark-grey);
  --color-overlay: rgba(0, 0, 0, 0.1);
  --color-bg: var(--color-creamwhite);
  --color-beige: #edede2;
  --color-brown: #4a4a4a;
  --color-dark-pink: #e8b5c6;
  --color-pink: #efced9;
  --color-light-pink: #f0d4df;
  --color-co-grey: #e4e7ec;
  --success-green: #47a66c;

  --outline-default: auto 5px -webkit-focus-ring-color;

  --box-padding: 1.8rem;
  --border-radius: 0.4rem;
  --hover-opacity: 0.8;
  --active-opacity: 0.6;

  // Z-INDEXES
  --header-z-index: 5;
  --drawer-z-index: 10;
  --backdrop-z-index: 1;
  --picker-overlay: 100;
  --cookiebar-z-index: 900;

  // EASINGS
  --easeInOutQuart: cubic-bezier(0.76, 0, 0.24, 1);
  --easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
  --easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --easeOutBackMore: cubic-bezier(0.175, 0.885, 0.385, 1.85);
  --easeAuthenticMotion: cubic-bezier(0.4, 0, 0.2, 1);

  // FONT-SIZES
  --font-size-h1: 4.4rem;
  --font-size-h2: 3.8rem;
  --font-size-h3: 1.7rem;
  --font-size-big: 2.2rem;
  --font-size-normal: 1.4rem;
  --font-size-small: 1.1rem;

  // FONTS
  --font-headline: 'NudieJeansHeadline', sans-serif;
  --font-text: 'UniversNext', 'universnext', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
  --font-monospace: 'Courier Prime', monospace;

  // Animations
  --authentic-motion: cubic-bezier(0.4, 0, 0.2, 1);
  --easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --easeOutBackMore: cubic-bezier(0.175, 0.885, 0.385, 1.85);
}