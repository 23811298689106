.wrapper {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  svg {
    fill: currentColor;
    color: currentColor;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .pauseButtonWrapper {
    opacity: 0;
    transform: translate3d(0, 0.5rem, 0);
    transition: opacity 200ms, transform 200ms;
  }

  &.playing {
    .playButton {
      opacity: 0 !important;
      transform: translate3d(0, 1rem, 0);
    }

    .pauseButtonWrapper {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.pauseButton,
.playButton,
.muteButton {
  color: #fff;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  background: transparent;
}

.controlDrawer {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
}

.noControlsBtn {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
}

.playButton {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%; //calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  transition: opacity 200ms, transform 200ms;

  > div {
    span {
      font-size: 1.2rem;
    }

    .playButtonSvgWrapper {
      border: 0.15rem solid currentColor;
      width: 7rem;
      height: 7rem;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 50%;
      margin: 0 auto 1rem;

      // svg {
      //   margin-left: 2rem;
      // }
    }
  }
}
