@import './variables.scss';

html {
  font-family: var(--font-text);
  margin: 0;
  min-height: 100%;
  color: var(--color-black);
  font-size: calc(62.5% * var(--sw));
}

#site-wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  padding-top: var(--header-height);

  &[data-transparent-header] {
    padding-top: 0;

    .nj-main-footer {
      margin-top: 0; // remove default margin top on footer
    }
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  margin: 0;
  font-weight: 400;
  line-height: 1.4;
  font-size: var(--font-size-normal); //1.6rem;
}

html,
body {
  background-color: #000;

  ::selection {
    background-color: #000;
    color: #fff;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

strong,
b {
  font-weight: 500;
}

img,
svg {
  max-width: 100%;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
col,
p,
a,
ol,
fieldset,
menu,
figure,
span {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: 400;
  font-size: 100%;
  line-height: inherit;
}

ul,
ol {
  list-style: none;
}

button {
  cursor: pointer;
}

address {
  font-style: normal;
}

input {
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
}

button {
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  line-height: inherit;
  padding: 0;
  margin: 0;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: center;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  outline: none;

  &:focus-visible {
    outline: var(--outline-default);
  }
}

a {
  text-decoration: none;
  color: inherit;
}

a[href^='tel:'] {
  color: inherit;
}

mark {
  background: rgb(249, 249, 171);
}

.site {
  min-height: 100vh;
  background: #000;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
}

#aino-content {
  // min-height: calc(100vh - var(--header-height));
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #000;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: none;
}

#nprogress .spinner {
  display: none;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

.visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
